<template>
  <div class="w-100">
    <PageConfiguration :stage="stage" class="mt-4 px-3" />
    <hr class="divider mb-4 mt-0" />
    <EmailTemplates :stage="stage" :funnel="funnel" class="px-3" />
    <hr class="divider mb-4 mt-0" />
    <PageLink :stage="stage" :funnel="funnel" @updated="$emit('updated', $event)" class="px-3" />
  </div>
</template>

<script>
import EmailTemplates from '../sections/EmailTemplates';
import PageLink from '../sections/PageLink';
import PageConfiguration from '../sections/PageConfiguration';

export default {
  components: {
    EmailTemplates,
    PageLink,
    PageConfiguration,
  },
  props: {
    funnel: {
      type: Object,
      required: true,
    },
    stage: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.divider {
  border: 1px solid $grey-17;
}
</style>
