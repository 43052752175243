<template>
  <div class="w-100 px-3 px-md-4">
    <Checkout v-bind="[$attrs, $props]" class="my-4" />
    <hr style="border: 1px solid #E9E9E9;" class="mb-4 mt-0" />
    <EmailTemplates v-bind="[$attrs, $props]" />
  </div>
</template>

<script>
import EmailTemplates from '../sections/EmailTemplates';
import Checkout from '../sections/Checkout';

export default {
  components: {
    Checkout,
    EmailTemplates,
  },
};
</script>
