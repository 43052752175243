<template>
  <div
    class="details-wrapper position-absolute w-100 h-100 overflow-auto shadow-sm d-flex align-items-start flex-column"
  >
    <div class="d-flex align-items-start flex-column w-100">
      <div class="details-header bg-white w-100 d-flex align-items-start py-5 shadow border-top">
        <hs-button variant="link" class="pt-1" @click="$emit('hide')">
          <hs-icon icon="arrow-left" class="text-dark" size="23" />
        </hs-button>
        <div class="text-center d-flex flex-column align-items-center mr-5 ml-1 w-100">
          <h3 class="font-weight-bold text-capitalize mb-3">
            {{ stage.name }}
          </h3>
          <PublishmentStatus :published="stage.isPublished" />
        </div>
      </div>
      <div class="w-100 d-flex align-items-start flex-column">
        <component v-if="correctComponent" :is="correctComponent" :funnel="funnel" :stage="stage" />
      </div>
    </div>
  </div>
</template>

<script>
import { hsLoading } from '@/components';
import WebinarStage from './WebinarStage';
import CheckoutStage from './CheckoutStage';
import PageStage from './PageStage';
import PublishmentStatus from '../components/PublishmentStatus';

export default {
  components: {
    PublishmentStatus,
    hsLoading,
  },
  props: {
    funnel: {
      type: Object,
      required: true,
    },
    stage: {
      type: Object,
      required: true,
    },
  },
  computed: {
    correctComponent() {
      switch (this.stage.type) {
        case 'Funnel::CheckoutStage':
        case 'Funnel::EadboxCheckoutStage':
        case 'Funnel::EdoolsCheckoutStage':
        case 'Funnel::HotmartCheckoutStage':
          return CheckoutStage;
        case 'Funnel::WebinarStage':
          return WebinarStage;
        default:
          return PageStage;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.details-wrapper {
  right: 0;
  min-height: 100%;
  top: 0;
  background-color: $white;
  max-width: 330px;

  @media only screen and (max-width: $screen-bs-sm) {
    background-color: $grey-10;
    max-width: 100%;
  }

  @media only screen and (min-width: $screen-bs-xl) {
    min-width: 332px;
  }
}

.back-edition {
  font-size: 12px;
  color: $grey-25;
}

.details-header {
  // Safari fix
  flex: 1 0 auto;
}
</style>
